<template>

	<div>
		<div class="block-header">
			<div class="row">
				<div class="col-lg-7 col-md-6 col-sm-12">
					<h2>用户管理
						<small class="text-muted"></small>
					</h2>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="body" @keyup.enter="queryList(1)">
							<el-row>
								<el-col :span="12">
									<el-input placeholder="请输入内容" v-model="query_keyword" size="mini"
										class="input-with-select">
										<el-button slot="append" icon="el-icon-search" @click="queryList()"></el-button>
									</el-input>

								</el-col>

								<el-col :span="3" :offset="5">
									<el-button-group>
										<el-button type="primary" size="mini" icon="el-icon-plus" @click="add">
										</el-button>
										<el-button type="danger" size="mini" icon="el-icon-delete" @click="del">
										</el-button>
									</el-button-group>
								</el-col>

							</el-row>
							<br /><br />

							<el-table ref="multipleTable" :data="list" style="width: 100%" border
								@selection-change="handleSelectionChange" height="calc(100vh - 270px)" size="mini">
								<el-table-column type="selection"></el-table-column>
								<el-table-column label="序号" width="60px" align="center">
									<template v-slot="scope">
										{{scope.$index+1}}
									</template>
								</el-table-column>
								<el-table-column prop="name" label="姓名" align="center"></el-table-column>
								<el-table-column prop="username" label="用户名" align="center"></el-table-column>
								<el-table-column prop="cellphone" label="手机号" align="center"></el-table-column>
								<el-table-column prop="dept_name" label="部门名称" align="center"></el-table-column>
								<el-table-column label="权限" align="center">
									<template v-slot="scope">
										<el-tag v-if="scope.row.role===item.id" v-for="(item,index) in roles">
											{{item.name}}</el-tag>
									</template>
								</el-table-column>
								<el-table-column prop="email" label="邮箱" align="center"></el-table-column>
								<el-table-column label="操作" align="center">
									<template v-slot="scope">
										<el-button-group>
											<el-button icon="el-icon-edit" type="primary" size="mini"
												@click="editor(scope.row)">编辑</el-button>
											<el-button icon="el-icon-refresh-right" type="infor" size="mini"
												@click="resetPassword(scope.row)">重置密码</el-button>
										</el-button-group>
									</template>
								</el-table-column>
							</el-table>

						</div>
					</div>
				</div>
			</div>
		</div>


		<el-dialog title="用户编辑" :visible.sync="dialogVisible" width="30%" :modal="false">

			<el-form ref="form" label-width="80px" size="mini">

				<el-row>
					<el-col :span="18">
						<el-form-item label="部门归属" required>
							<el-select v-model="current_user.dept_name" filterable placeholder="可在输入框内搜索..."
								style="min-width: 300px;">
								<el-option v-for="item in depts" :key="item.id" :label="item.name" :value="item.name">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="18">
						<el-form-item label="姓名" required>
							<el-input v-model="current_user.name" placeholder="请输入姓名"></el-input>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="18">
						<el-form-item label="用户名">
							<el-input v-model="current_user.username" placeholder="请输入用户名"></el-input>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="18">
						<el-form-item label="手机号">
							<el-input v-model="current_user.cellphone" placeholder="请输入手机号码"></el-input>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row v-if="!current_user.unique">
					<el-col :span="18">
						<el-form-item label="密码" required>
							<el-input v-model="current_user.password" placeholder="请输入登陆密码"></el-input>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="18">
						<el-form-item label="权限" required>
							<el-select v-model="current_user.role" placeholder="请选择">
								<el-option v-for="(item,index) in roles" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>

				<el-divider>非必填</el-divider>

				<el-row>
					<el-col :span="18">
						<el-form-item label="性别">
							<el-radio v-model="current_user.sex" label="男">男</el-radio>
							<el-radio v-model="current_user.sex" label="女">女</el-radio>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="18">
						<el-form-item label="邮箱">
							<el-input v-model="current_user.email" placeholder="请输入邮箱"></el-input>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-form-item label="">
						<el-button type="primary" style="min-width: 320px;height: 40px;" @click="submit">
							{{current_user.unique?'立即保存':'立即添加'}}
						</el-button>
					</el-form-item>
				</el-row>

			</el-form>

		</el-dialog>

	</div>

</template>

<script>
	import userController from "../controller/userController.js"
	import deptController from '../controller/deptController.js'
	import roleController from "../controller/roleController.js"
	export default {

		data() {
			return {
				multipleSelection: [],
				list: [],
				roles: [],
				depts: [],
				query_keyword: null,
				query_dept: null,
				dialogVisible: false,
				current_user: {

				}
			}
		},
		created() {
			deptController.list({}).then(res => this.depts = res.data)
			roleController.list({}).then(res => this.roles = res.data)
			this.queryList()
		},
		methods: {
			queryList() {
				userController.list({
					query_keyword: this.query_keyword,
					query_dept: this.query_dept,
				}).then(res => {
					this.list = res.data;
				})

			},
			add() {
				this.current_user = {
					sex: '男',
				}
				this.dialogVisible = true;

			},
			editor(obj) {
				userController.get({
					unique: obj.unique
				}).then(res => {
					this.current_user = res.data;
					this.dialogVisible = true;
				})
			},
			resetPassword(obj) {


				this.$prompt("重置 '" + obj.name + "' 密码", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(({
					value
				}) => {

					if (value) {
						userController.resetPassword({
							unique: obj.unique,
							password: value
						}).then(res => {
							this.queryList();
						})
					}
				}).catch(() => {});

			},
			del() {
				if (this.multipleSelection.length > 0) {

					this.$confirm('此操作将永久删除勾选数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let array = [];
						for (let i = 0; i < this.multipleSelection.length; i++)
							array.push(this.multipleSelection[i].unique)
						userController.del({
							uniques: array.toString()
						}).then(res => this.queryList())
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						})
					});

				} else {
					this.$message.error('请至少勾选一条数据')
				}
			},
			submit() {

				if (!this.validate(this.current_user))
					return;

				let obj = this.deepClone(this.current_user)
				if (this.current_user.unique) {
					userController.put(obj).then(res => {
						this.dialogVisible = false;
						this.queryList()
					})

				} else {
					obj.password = this.base64Encode(obj.password)
					userController.post(obj).then(res => {
						this.dialogVisible = false;
						this.queryList()
					})
				}
			},
			validate(obj) {

				if (!obj.dept_name) {
					this.$message.error('请选择归属部门')
					return false;
				}

				if (!obj.name) {
					this.$message.error('请填写姓名')
					return false;
				}

				if (!obj.unique && !obj.cellphone && !obj.username) {
					this.$message.error('用户名/手机号 至少填写一项')
					return false;
				}

				if (!obj.unique && !obj.password) {
					this.$message.error('请填写密码')
					return false;
				}

				if (!obj.role) {
					this.$message.error('请设置权限')
					return false;
				}

				return true;
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		}

	}
</script>

<style scoped>

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}
</style>
